<template>
  <!-- 配置列表 -->
  <div>
    <!-- 顶部新增按钮 -->
    <div class="onBtn">
      <el-button
        type="danger"
        size="mini"
        @click="addNewProfile = true"
        style="margin-left: auto"
        >新增</el-button
      >
    </div>

    <!-- 中间表格区 -->
    <el-table :data="data">
      <el-table-column
        label="配置ID"
        align="center"
        width="80"
        prop="id"
      ></el-table-column>
      <el-table-column
        label="平台"
        align="center"
        width="80"
        prop="platform"
      ></el-table-column>
      <el-table-column
        label="主体配置"
        align="center"
        width="150"
        prop="configuration"
      ></el-table-column>
      <el-table-column
        label="内容"
        align="center"
        width="300"
        prop="content"
      ></el-table-column>
      <el-table-column
        label="备注"
        align="center"
        width="300"
        prop="note"
      ></el-table-column>
      <el-table-column
        label="时间"
        align="center"
        width="300"
        prop="times"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template>
          <div class="config">
            <div @click="toView = true">查看</div>
            <div @click="editor = true">编辑</div>
            <div>修改配置项</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增弹窗 -->
    <el-dialog title="新增" :visible.sync="addNewProfile" width="30%">
      <el-form :model="newData" label-width="60px">
        <el-form-item label="主题">
          <el-input
            v-model="newData.theme"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            v-model="newData.content"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台">
          <el-select
            v-model="newData.platform"
            disabled
            placeholder=""
            style="width: 300px"
          >
            <el-option label="TEST" value="TEST"></el-option>
            <el-option label="QJT" value="QJT"></el-option>
            <el-option label="FXB" value="FXB"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="newData.note"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addNewProfile = false">取 消</el-button>
        <el-button type="primary" @click="addNewProfile = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 查看弹窗 -->
    <el-dialog title="查看" :visible.sync="toView" width="30%">
      <el-form :model="newData" label-width="60px">
        <el-form-item label="主题">
          <el-input
            v-model="newData.theme"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            v-model="newData.content"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台">
          <el-select
            v-model="newData.platform"
            disabled
            placeholder=""
            style="width: 300px"
          >
            <el-option label="TEST" value="TEST"></el-option>
            <el-option label="QJT" value="QJT"></el-option>
            <el-option label="FXB" value="FXB"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="newData.note"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 编辑弹窗 -->
    <el-dialog title="新增" :visible.sync="editor" width="30%">
      <el-form :model="newData" label-width="60px">
        <el-form-item label="主题">
          <el-input
            v-model="newData.theme"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input
            v-model="newData.content"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台">
          <el-select
            v-model="newData.platform"
            disabled
            placeholder=""
            style="width: 300px"
          >
            <el-option label="TEST" value="TEST"></el-option>
            <el-option label="QJT" value="QJT"></el-option>
            <el-option label="FXB" value="FXB"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="newData.note"
            placeholder=""
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editor = false">取 消</el-button>
        <el-button type="primary" @click="editor = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 新增
      addNewProfile: false,
      //   查看
      toView: false,
      //   编辑
      editor: false,
      show: "",
      data: [
        {
          id: 1,
          platform: "所有",
          configuration: "recharge_accoun",
          content: "",
          note: "提现配置",
          times: "2018-07-26 14:55:37",
        },
      ],
      newData: {
        theme: "",
        content: "",
        platform: "全部",
        note: "",
      },
      total: 23,
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
    };
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.config {
  display: flex;
  justify-content: space-around;
  div {
    font-size: 10px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
